import {H2, H1, H3} from '../components/headers';
import {Button} from '../components/buttons';
import {useRef, useEffect} from 'react';
import anime from 'animejs';
import Video from '../components/world.webm';

function Landing(){
    const ref = useRef(null);
    const box_ref = useRef(null);

    const animate = () => {
        anime.timeline().add({
            targets: box_ref.current,
            width: ['0%', '100%'],
            easing:'easeOutExpo',
            duration: 1400, 
        });

        anime.timeline({loop:true}).add({
            targets: ref.current.querySelectorAll('.project'),
            
        })
    }

    useEffect(() => {
       //animate();
    }, []);
    return(
        <div className="container flex col">
            
            <video id="video-landing" autostart autoPlay muted loop>
                <source src={Video} type="video/webm"/>
            </video>

            <div className={"inner-container max-height center justify glass border-right"}>
                <div>
                    <H1 text={"SPRINGSTAKE"}/>
                    <H3 text={"Accessible Staking for Everyone"}/>
                    <div className={'margin-top margin-bottom'}></div>
                </div>
            </div>

        </div>
    );
}

export default Landing;