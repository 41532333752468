import {useRef, useEffect} from 'react';
import anime from 'animejs';

function Button({text, href, icon, alt, target, delay}){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop: false}).add({
            targets: ref.current,
            translateY: [100, 0],
            duration: 1400,
            easing: 'easeOutExpo',
            delay: delay
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    if(icon){
    return(
        <div className={"button-container"}>
            <a ref={ref} href={href} target={target} className="button icon">
                <img className="button-icon" src={icon}/>
            </a>
        </div>
    );
    }
    return(
        <div className={"button-container"}>
            <a ref={ref} href={href} target={target} className={`button ${alt}`}>
                {text}
            </a>
        </div>
    );
}

export {Button};