import {H1, H3} from './headers.js';
import {Button} from './buttons';

function Footer(){
    return(
        <div id="footer" className={"footer flex row center "}>
                <Button icon={'./img/Twitter_logo.svg'} href={"https://twitter.com/Spring_Stake"}  target="_blank"/>
                <p className={'margin-left margin-top'}>socials@springstake.com</p>
        </div>
            
     
    );
};

export default Footer;