import {H2, H1, H3, SmallHeader} from '../components/headers';
import {Button} from '../components/buttons';
import Card from '../components/card';
import Protocol from '../components/protocol';
import {useEffect, useRef} from 'react';
import anime from 'animejs';




function Protocols(){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current,
            translateY: [20, 0],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 1000,
            delay: 250 
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <div id="networks" className="flex row justify center half-height reverse">
            <div className="flex col margin-bottom reverse margin-right">
                <H3 text={"Networks we Trust - Coming soon"}/>
                <p style={{width: '25rem'}}>
                    Be part of the future - today. Our developed solutions benefit our users and these networks as a whole.
                </p>
            </div>

        </div>
    );
}

export default Protocols;