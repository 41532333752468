import {useEffect, useRef} from 'react';
import anime from 'animejs';

function Navbar(){
    const nav_logo = useRef(null);
    const nav_items = useRef(null);
    const animate = () => {
        anime.timeline({loop: false}).add({
            targets: nav_logo.current.querySelector('.logo'),
            opacity: [0, 1],
            duration: 1400,
            easing: 'easeOutExpo'
        });
        anime.timeline({loop:false}).add({
            targets: nav_items.current.querySelectorAll('.nav-item p'),
            opacity: [0, 1],
            duration: 1400,
            easing: 'easeOutExpo',
            delay: (el, i) => 100 * i
        });
    };

    useEffect(() => {
        animate();
    }, []);
    return(
        <div className={"navbar flex"}>
            
            <div ref={nav_logo} className="logo-container hide-overflow flex center justify" style={{ position: 'absolute'}}>
                <a href="/" className={"logo flex center row"}>
                    <img src="./img/logo.svg"/>
                </a>
            </div>

            
            <div className={"flex row max-width center justify"}>
                <div ref={nav_items} className="align-right">

                    <a href="#about" className="nav-item flex col">
                        <p>About</p>  
                    </a>

                    <a href="#mission" className="nav-item">
                        <p>Our Mission</p>
                    </a>

                    <a href="#networks" className="nav-item">
                        <p>Networks</p>
                    </a>

                    <a href="#footer" className="nav-item">
                        <p>Contact</p>
                    </a>
                </div>
            </div>

        </div>
    );
}

export default Navbar;