import {useRef, useEffect} from 'react';
import anime from 'animejs'

function H3({text}){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current,
            translateY: [20, 0],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 1000,
            delay: 500
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.25 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <h3 ref={ref} >{text}</h3>
    );
}

function H2({text}){
    const words = text.split(/\s+/);
    const wrapped_words = words.map((word, index) => (
            <span className="letter-wrapper">
                <span className="letter">
                    {word}
                    &nbsp;
                </span>
            </span>
          
      ));
 
    const ref = useRef(null);

    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current.querySelectorAll('.letter-wrapper .letter'),
            translateY: [100,0],
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 1400,
            delay: (el, i) => 100 * i
          });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.25 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <h2 ref={ref}>{wrapped_words}</h2>
    );
}

function H1({text, alt}){
    const words = text.split(/\s+/);
    const wrapped_words = words.map((word, index) => (
            <span className="letter-wrapper">
                <span className="letter">
                    {word}
                    &nbsp;
                </span>
            </span>
          
      ));
 
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current.querySelectorAll('.letter-wrapper .letter'),
            translateY: [100,0],
            opacity: [0, 1],
            easing: 'easeOutExpo',
            duration: 1400,
            delay: (el, i) => 100 * i
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    if(alt){
        return(
            <h1 ref={ref} className="alt">{wrapped_words}</h1>
        );
    }
    return(
        <h1 ref={ref}  >{wrapped_words}</h1>
    );
}

function SmallHeader({text, alt}){
    const refLine = useRef(null);
    const textLine = useRef(null);

    const animate = () =>{
        anime.timeline({loop: false}).
        add({
            targets: refLine.current.querySelector('.refline-text'),
            translateY: [100,0],
            rotate: [45, 0],
            easing: "easeOutExpo",
            duration: 1000
        });

        anime.timeline({loop: false})
        .add({
            targets: textLine.current,
            width: ['0%', '100%'],
            easing: "easeOutQuart",
            duration: 1000
        });
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                if (entry.isIntersecting) {
                    animate();
                    observer.disconnect();
                }
                });
            },
            { threshold: 0.25 } // Trigger when at least 10% of the element is visible
            );
    
            if (refLine.current) {
            observer.observe(refLine.current);
            }
    
            return () => {
            if (refLine.current) {
                observer.unobserve(refLine.current);
            }
            };
       
    }, []);

    if(alt){
        return(
        <div>
            <h4 ref={refLine} className={"alt"}>
                <span className='refline-text'>{text}</span>
            </h4>
            <div ref={textLine} className="line"></div>
        </div>
        );
    }
    return(
        <div>
            <h4 ref={refLine}>
                <span className='refline-text'>{text}</span>
            </h4>
            <div ref={textLine} className="line"></div>
        </div>
    );
}

function H4({text}){
    const ref = useRef(null);
    const animate = () => {
        anime.timeline({loop:false}).add({
            targets: ref.current,
            translateY: [20, 0],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 1000,
            delay: 500
        });
    }
    useEffect(() => {
        const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
            if (entry.isIntersecting) {
                animate();
                observer.disconnect();
            }
            });
        },
        { threshold: 0.1 } // Trigger when at least 10% of the element is visible
        );

        if (ref.current) {
        observer.observe(ref.current);
        }

        return () => {
        if (ref.current) {
            observer.unobserve(ref.current);
        }
        };
    }, []);

    return(
        <h4 ref={ref}>{text}</h4>
    );
}

export {H1, H2, H3, H4, SmallHeader};