import {Button} from '../components/buttons';
import anime from 'animejs';
import {useEffect, useRef, useState} from 'react';
import {H2, H1, H3} from '../components/headers';

function Mission(){
    const container = useRef(null);
    let [dashes, setDashes] = useState([]);

    useEffect(()=>{
        console.log('mission');
        const height = container.current.clientHeight/20;
        const d = <div class="dash" style={{marginTop: height + 'px'}}></div>;

        var dashArray = [];
        for(var i = 1; i < 19; i++){
            dashArray.push(d);
        }
        setDashes(dashArray);
    }, []);
    return(
        <div ref={container} id="mission" className="center justify flex three-quarters-height border-bottom reverse" >
            <div className="lines align-left" style={{position: 'absolute', left: '0'}}>
                {dashes}
            </div>
            <div className="flex col justify center reverse partial-width">
                <p>our mission</p>
                <H3 text="Crypto is about accessibility – inaccessability in crypto prevent mass adoption. SPRINGSTAKE's mission is to make Web3 available to everyone."/>
            </div>
            <img className="align-bottom" id="lines" src="./img/Lines.svg"/>

            <div className="lines" style={{position: 'absolute', right: '0'}}>
                {dashes}
            </div>

        </div>
    );
}

export default Mission;